import React from "react";

export function H1(props) {
  return <div style={{ ...styles.h1, ...props.style }}>{props.children}</div>;
}

export function H2(props) {
  return <div style={{ ...styles.h2, ...props.style }}>{props.children}</div>;
}

export function H3(props) {
  return <div style={{ ...styles.h3, ...props.style }}>{props.children}</div>;
}

const styles = {
  h1: {
    fontSize: 52,
    fontWeight: 400,
    letterSpacing: 0.2,
    lineHeight: 1.1,
  },
  h2: {
    fontSize: 40,
    fontWeight: 500,
    letterSpacing: 0.2,
    lineHeight: 1.2,
  },
  h3: {
    fontSize: 24,
    fontWeight: 400,
    letterSpacing: 0.2,
    lineHeight: 1.4,
  },
};
