import React, { useState, useEffect } from "react";
import Rodal from "rodal";
import { useMediaQuery } from "react-responsive";
import { Button, TextInput, TextArea } from "@Components/index";
import { FiX } from "react-icons/fi";
import { SendContactEmail } from "./services";
// import { parsePhone } from "./utility";
import { validateEmail } from "./utility";
import { toast } from "react-toastify";

// Demo request / contact Modal

export function openModal() {
  return Modal.openModal();
}

export default function Modal() {
  const [stage, setStage] = useState(1);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  // const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  // sm breakpoint
  const isMobile = useMediaQuery({ query: "(max-width: 575px)" });

  Modal.openModal = () => {
    setStage(1);
    setOpen(true);
  };

  useEffect(() => {
    if (open) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
  }, [open]);

  const style = { ...styles.modal };

  if (isMobile) {
    style.height = "100%";
    style.borderRadius = 0;
    style.padding = "50px 25px";
  }

  let title = "Speak with an Online Ordering Expert";
  if (stage === 2) title = "You're all set!";

  return (
    <Rodal visible={open} onClose={onClose} customStyles={style}>
      <FiX onClick={onClose} style={styles.xIcon} className="hover-red" />
      <div style={styles.title}>{title}</div>
      <div style={{ fontSize: 18 }}> {renderContent()}</div>
    </Rodal>
  );

  function renderContent() {
    if (stage === 1)
      return (
        <div>
          <TextInput
            label="Full name"
            value={name}
            onChange={setName}
            inputStyle={styles.input}
          />
          <TextInput
            label="Email address"
            value={email}
            onChange={setEmail}
            inputStyle={styles.input}
          />
          <TextArea
            label="How can we help?"
            value={message}
            onChange={setMessage}
            inputStyle={styles.textArea}
          />
          <Button
            filled
            title="Get Started"
            style={styles.button}
            className="theme-button"
            type="button"
            onClick={onSubmit}
          />
        </div>
      );

    if (stage === 2) {
      return (
        <div>
          A member of our team will reach out within the next 24 hours to assist
          you. Thank you for your interest in Expresso.
        </div>
      );
    }
  }

  function onClose() {
    setOpen(false);
  }

  async function onSubmit() {
    if (!name.trim() || !email.trim() || !message.trim())
      return toast.error("All fields are required");

    const e = validateEmail(email);
    if (e) return toast.error(e);
    // const payload = { name, email, phone: parsePhone(phone), message };
    const payload = { name, email, message };

    await SendContactEmail(payload);
    setStage(2);
  }
}

const styles = {
  modal: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    maxWidth: 500,
    position: "relative",
    height: "auto",
    width: "inherit",
    padding: 40,
    borderRadius: 12,
  },
  modalHeaderWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 24,
  },
  button: {
    minWidth: 200,
    fontWeight: 500,
    fontSize: 18,
    minHeight: 50,
    height: 50,
    boxShadow: "none",
    marginTop: 30,
    borderRadius: 8,
  },
  title: {
    fontSize: 32,
    fontWeight: 500,
    lineHeight: 1.3,
    marginBottom: 40,
    marginTop: 10,
    // textAlign: "center",
  },
  xIcon: {
    minWidth: 24,
    fontSize: 24,
    position: "absolute",
    top: 14,
    right: 14,
    transition: 0.5,
  },
  input: {
    border: "none",
    backgroundColor: "#eee",
    fontSize: 18,
    height: 40,
    width: "100%",
    maxWidth: "100%",
    padding: 8,
    borderRadius: 8,
  },
  textArea: {
    border: "none",
    backgroundColor: "#eee",
    fontSize: 18,
    width: "100%",
    maxWidth: "100%",
    padding: 8,
    borderRadius: 8,
  },
};
