import React from "react";
import InfoHover from "./InfoHover";

export const TextInput = React.forwardRef((props, ref) => {
  const {
    disabled,
    password,
    optional,
    infoHover,
    label,
    value,
    onChange,
    maxLength = 1000,
    placeholder,
  } = props;

  let type = props.type || "text";
  if (password) type = "password";

  const nextProps = {
    ref,
    disabled,
    value,
    type,
    placeholder,
    maxLength,
  };

  return (
    <div style={{ marginBottom: 16, ...props.containerStyle }}>
      <div style={{ marginBottom: 2 }}>
        {label}
        {optional && <span style={{ color: "gray" }}> - Optional</span>}
        {infoHover && renderInfoHover()}
      </div>
      <input
        {...nextProps}
        onChange={(evt) => onChange(evt.target.value)}
        style={{ ...styles.inputStyle, ...props.inputStyle }}
      />
    </div>
  );

  function renderInfoHover() {
    const { content } = infoHover;
    if (!content) return;
    return <InfoHover>{content}</InfoHover>;
  }
});

export function TextArea(props) {
  const {
    disabled,
    optional,
    infoHover,
    label,
    value,
    onChange,
    maxLength = 1000,
    placeholder,
    rows = 3,
  } = props;
  return (
    <div className="mb-8">
      <div style={{ marginBottom: 2 }}>
        {label}
        {infoHover && renderInfoHover()}
      </div>
      {optional && <span className="gray"> - Optional</span>}
      <textarea
        rows={rows}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={(evt) => onChange(evt.target.value)}
        maxLength={maxLength}
        style={{
          maxWidth: 400,
          backgroundColor: "#f3f4f6",
          padding: "8px 12px",
          border: "none",
          ...props.inputStyle,
        }}
      />
    </div>
  );

  function renderInfoHover() {
    const { content } = infoHover;
    return <InfoHover>{content}</InfoHover>;
  }
}

export function Checkbox(props) {
  const { hidden, disabled, label, infoHover, checked, style } = props;

  let className = "";
  if (!disabled) className += "form-checkbox";
  if (props.error) className += " delete";

  if (hidden) return null;
  return (
    <div style={style}>
      <span style={styles.checkbox} className={className} onClick={onClick}>
        <input disabled={disabled} type="checkbox" readOnly checked={checked} />
        {label}
        {infoHover && renderInfoHover()}
      </span>
    </div>
  );

  function renderInfoHover() {
    const { content } = infoHover;
    return <InfoHover>{content}</InfoHover>;
  }

  function onClick() {
    if (disabled) return;
    props.onClick();
  }
}

const styles = {
  inputStyle: {
    height: 40,
    maxWidth: 400,
    padding: "8px 12px",
    backgroundColor: "#f3f4f6",
    border: "none",
  },
  checkbox: {
    display: "inline-flex",
    alignItems: "center",
  },
};
