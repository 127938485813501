import React, { useState } from "react";
import Resizer from "react-image-file-resizer";
import Dropzone from "react-dropzone";
import Spinner from "react-loading";
import { GetSignedUrl, UploadFile } from "../services";

export default function ImageSelect(props) {
  const { imageURL } = props;

  const [ready, setReady] = useState(false);
  const [waiting, setWaiting] = useState(false);

  return (
    <>
      <Dropzone accept="image/*" maxFiles={1} onDrop={onFile}>
        {({ getRootProps, getInputProps }) => {
          return (
            <section>
              <div {...getRootProps()} style={styles.dropzone}>
                <input {...getInputProps()} />
                {renderContent()}
              </div>
            </section>
          );
        }}
      </Dropzone>
      {imageURL && ready && (
        <span
          onClick={onDelete}
          style={styles.delete}
          className="hover-opacity"
        >
          Remove Image
        </span>
      )}
    </>
  );

  function renderContent() {
    if (waiting)
      return (
        <div style={styles.spinner}>
          <Spinner type="spin" color="white" />
        </div>
      );

    if (!imageURL) return "Drag image here, or click to select file.";

    const imageStyle = { ...styles.image };
    if (!ready) imageStyle.opacity = 0;
    return (
      <img
        onLoad={() => setReady(true)}
        src={imageURL}
        style={styles.image}
        alt="menu-item"
      />
    );
  }

  async function onFile(files) {
    const file = files[0];
    if (!file) return;

    const image = await resizeImage(file);

    setWaiting(true);
    const { data } = await GetSignedUrl({ type: image.type });
    const { url, signedRequest } = data;
    await UploadFile({ signedRequest, file: image });
    setWaiting(false);
    if (props.onFile) props.onFile(url);
  }

  async function onDelete() {
    if (props.onDelete) props.onDelete(null);
  }
}

function resizeImage(file) {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(file, 1000, 1000, "JPEG", 80, 0, resolve, "file");
  });
}

const styles = {
  dropzone: {
    backgroundColor: "#eee",
    borderRadius: 20,
    padding: 20,
    height: 300,
    width: 300,
    position: "relative",
    cursor: "pointer",
    overflow: "hidden",
  },
  spinner: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    left: 0,
  },
  image: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    objectFit: "cover",
  },
  delete: {
    color: "var(--delete)",
    fontSize: 14,
  },
};
