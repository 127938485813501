import React from "react";
import ReactLoading from "react-loading";
import { themeColor } from "../theme";

export default function Loader(props) {
  return (
    <span style={{ position: "relative" }}>
      <ReactLoading
        className={`loader ${props.display ? "" : "hide"}`}
        type="cylon"
        color={themeColor}
        width={40}
      />
    </span>
  );
}

export function Spokes(props) {
  const { color = themeColor } = props;
  return (
    <div style={{ ...styles.loader, ...props.style }}>
      <ReactLoading type="spokes" color={color} />
    </div>
  );
}

const styles = {
  loader: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 200,
  },
};
