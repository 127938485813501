import React, { Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Toastify.css";

import ContactModal from "./ContactModal";

// lazy loading at route level
const Auth = lazy(() => import("./Router"));
const Login = lazy(() => import("./Login"));
const Register = lazy(() => import("./Register"));
const PasswordReset = lazy(() => import("./PasswordReset"));
const Recovery = lazy(() => import("./Recovery"));
const Homepage = lazy(() => import("./Homepage"));
const Activate = lazy(() => import("./Activate"));
const Pay = lazy(() => import("./Pay"));
const Checkout = lazy(() => import("./Checkout"));
const Error = lazy(() => import("./Error"));
const Calendly = lazy(() => import("./Calendly"));

export default function App() {
  return (
    <>
      <ContactModal />
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        closeButton={false}
      />
      <Suspense fallback={null}>
        <BrowserRouter>
          <Switch>
            <Route path="/auth" component={Auth} />
            <Route path="/login" component={Login} />
            <Route path="/password-reset" component={PasswordReset} />
            <Route path="/recovery" component={Recovery} />
            <Route path="/register" component={Register} />
            <Route path="/error" component={Error} />
            <Route path="/activate" component={Activate} />
            <Route path="/checkout" component={Checkout} />
            <Route path="/calendar" component={Calendly} />
            <Route path="/pay" component={Pay} />
            <Route path="/" component={Homepage} />
          </Switch>
        </BrowserRouter>
      </Suspense>
    </>
  );
}
