import React, { useState } from "react";
import { themeColor } from "../theme";

export default function InfoHover(props) {
  const [displayInfo, setDisplayInfo] = useState(false);

  return (
    <span style={{ position: "relative" }}>
      <i
        onMouseOver={() => setDisplayInfo(true)}
        onMouseLeave={() => setDisplayInfo(false)}
        className="far fa-question-circle clickable"
        style={styles.icon}
      />
      {displayInfo && <span style={styles.content}>{props.children}</span>}
    </span>
  );
}

const styles = {
  content: {
    position: "absolute",
    left: 35,
    top: -12,
    fontSize: 15,
    lineHeight: 1.3,
    color: "white",
    backgroundColor: "rgba(0,0,0,0.75)",
    width: 300,
    padding: 12,
    boxShadow: "0px 2px 3px rgba(0,0,0,.2)",
    zIndex: 1,
  },
  icon: {
    marginLeft: 6,
    color: themeColor,
  },
};
