import React, { useState } from "react";
import { FiX } from "react-icons/fi";
import Rodal from "rodal";
import { Buttons } from "./Button";
import "rodal/lib/rodal.css";
import "./modal.css";

export default function Modal(props) {
  const { submitText = "Submit", cancelText = "Back" } = props;

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    await props.onSubmit();
    setIsLoading(false);
  };

  return (
    <Rodal
      visible={props.isVisible}
      onClose={props.onClose}
      customStyles={styles.modal}
      duration={0}
    >
      {renderHeader()}
      {renderBody()}
      {renderButtons()}
    </Rodal>
  );

  function renderHeader() {
    return (
      <div style={styles.headerWrapper}>
        <div style={styles.headerText}>{props.title}</div>
        <FiX
          onClick={props.onClose}
          style={styles.headerIcon}
          className="hover-red"
        />
      </div>
    );
  }

  function renderBody() {
    return <div style={styles.body}>{props.children}</div>;
  }

  function renderButtons() {
    return (
      <Buttons
        waiting={isLoading}
        submitText={submitText}
        cancelText={cancelText}
        onSubmit={handleSubmit}
        onCancel={props.onClose}
      />
    );
  }
}

const styles = {
  modal: {
    display: "flex",
    flexDirection: "column",
    height: "auto",
    bottom: "auto",
    top: "auto",
    maxWidth: 500,
    padding: 20,
    borderRadius: 12,
  },
  headerWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 20,
  },
  headerText: {
    fontSize: 24,
    fontWeight: 500,
  },
  headerIcon: {
    fontSize: 26,
  },
  body: {
    fontSize: 18,
    flexGrow: 1,
    marginBottom: 10,
  },
};
