import React from "react";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import Loader from "./Loader";
import { themeColor } from "../theme";

export default function Button(props) {
  const style = { ...styles.button };

  let className = "hover-opacity";

  if (props.filled) {
    style.borderColor = themeColor;
    style.color = "white";
    style.fontWeight = "500";
    style.backgroundColor = themeColor;
  } else {
    style.backgroundColor = "#eee";
    style.borderColor = "#eee";
  }

  if (props.marginless) style.margin = 0;

  if (props.hide) return null;
  return (
    <button
      className={className}
      onClick={onClick}
      style={{ ...style, ...props.style }}
      disabled={props.disabled}
      type="button"
    >
      {props.title}
    </button>
  );

  function onClick(evt) {
    if (evt) evt.preventDefault();
    if (props.disabled) return;
    if (props.onClick) props.onClick();
  }
}

export function IconButton(props) {
  const Icon = require("react-icons/fa")[props.name];
  return (
    <div onClick={onClick} style={styles.iconButton} className="hover-opacity">
      <Icon />
    </div>
  );

  function onClick() {
    if (props.disabled) return;
    if (props.onClick) props.onClick();
  }
}

export function Buttons(props) {
  const {
    hide,
    waiting,
    onSubmit,
    hideCancel,
    cancelText = "Back",
    submitText = "Save",
  } = props;

  if (hide) return null;
  return (
    <div style={{ marginTop: 20 }}>
      <Button filled disabled={waiting} onClick={onSubmit} title={submitText} />
      <Button hide={hideCancel} onClick={onCancel} title={cancelText} />
      <Loader display={waiting} />
    </div>
  );

  function onCancel() {
    if (props.onCancel) props.onCancel();
  }
}

export function InstallButtons() {
  return (
    <div className="install-buttons">
      <button
        className="big-button"
        style={{ marginBottom: 15, marginRight: 10 }}
        onClick={() => {
          window.open(
            "https://apps.apple.com/us/app/expresso-order-by-voice/id1540012558",
            "_blank"
          );
        }}
      >
        <div className="align">
          <i className="fab fa-apple" />
          <div>Get App</div>
        </div>
      </button>
      <button
        className="big-button"
        onClick={() => {
          window.open(
            "https://play.google.com/store/apps/details?id=io.expresso.expresso",
            "_blank"
          );
        }}
      >
        <div className="align">
          <i className="fab fa-google-play" style={{ fontSize: 26 }} />
          <div>Get App</div>
        </div>
      </button>
    </div>
  );
}

export function CTAButton(props) {
  const { href } = props;

  const history = useHistory();

  return (
    <button
      onClick={onClick}
      style={{ ...styles.ctaButton, ...props.style }}
      className="theme-button"
    >
      {props.title || props.children}
    </button>
  );

  function onClick() {
    if (href) window.open(href);
    else history.push("/register");
  }
}

export function Config(props) {
  return (
    <div style={{ ...styles.config, ...props.style }}>
      {props.onEdit && (
        <FiEdit
          className="clickable"
          style={{ ...styles.configIcon, color: "#5469d4" }}
          onClick={props.onEdit}
        />
      )}
      {props.onDelete && (
        <FiTrash2
          className="clickable"
          style={{ ...styles.configIcon, color: "#ed2e4b" }}
          onClick={props.onDelete}
        />
      )}
    </div>
  );
}

const styles = {
  button: {
    padding: "4px 12px",
    minWidth: 80,
    height: 40,
    fontSize: 16,
    color: "#444",
    borderRadius: 8,
    userSelect: "none",
    border: "1px solid #ddd",
    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
    marginRight: 16,
  },
  iconButton: {
    width: 20,
    height: 20,
    fontSize: 14,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    backgroundColor: themeColor,
    borderRadius: 10,
    userSelect: "none",
    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
  },
  buttonWrapper: {
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "white",
    display: "inline-flex",
  },
  configIcon: {
    marginRight: 4,
    fontSize: 18,
    bottom: 2,
    position: "relative",
  },
  config: {
    display: "inline",
    whiteSpace: "nowrap",
    zIndex: 10,
  },
  ctaButton: {
    minWidth: 200,
    minHeight: 50,
    height: 50,
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: 0.8,
    userSelect: "none",
    border: "none",
    borderRadius: 4,
    color: "white",
    padding: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
