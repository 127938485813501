import axios from "axios";

const api =
  process.env.REACT_APP_ENV === "dev"
    ? "http://localhost:4999"
    : "https://api.expresso.io";

export function getToken() {
  return sessionStorage.getItem("token") || localStorage.getItem("token");
}

function headers() {
  const token = getToken();
  return { Authorization: token };
}

// get
function get(path, payload) {
  return axios({
    method: "get",
    url: api + path,
    params: payload,
    headers: headers(),
  });
}

// post
function post(path, payload) {
  return axios({
    method: "post",
    url: api + path,
    data: payload,
    headers: headers(),
  });
}

// Partner

export function Authorize(payload) {
  return post("/partner/authorize", payload);
}

export function IsPartner(payload) {
  return get("/partner/exists", payload);
}

export function GetTokenData() {
  return get("/partner/token-data");
}

export function GetPartnerData(keys) {
  return post("/partner/get-data", { keys });
}

export function SetPartnerData(payload) {
  return post("/partner/set-data", payload);
}

export function GetRestaurants() {
  return get("/partner/restaurants");
}

export function SetRestaurant(payload) {
  return post("/partner/set-restaurant", payload);
}

export function GetPartnerOrders(payload) {
  return post("/partner/get-orders", payload);
}

export function CreatePartner(payload) {
  return post("/partner/add", payload);
}

export function SetPassword(payload) {
  return post("/partner/set-password", payload);
}

export function SetHubID(payload) {
  return post("/partner/set-hub-id", payload);
}

export function UpdateCard(payload) {
  return post("/partner/update-card", payload);
}

export function GetInvoices() {
  return get("/partner/invoices");
}

export function GetInvoice(id) {
  return get("/partner/get-invoice", { id });
}

// Restaurants

export function AddRestaurant(payload) {
  return post("/restaurant/add", payload);
}

export function GetRestaurantData(keys) {
  return post("/restaurant/get-data", { keys });
}

export function SetRestaurantData(payload) {
  return post("/restaurant/set-data", payload);
}

export function GetRequirements() {
  return get("/restaurant/requirements");
}

export function SetLocation(payload) {
  return post("/restaurant/set-location", payload);
}

export function SetWebsite(payload) {
  return post("/restaurant/set-website", payload);
}

export function GetOrders(payload) {
  return post("/restaurant/orders", payload);
}

export function GetOverview(payload) {
  return post("/restaurant/overview", payload);
}

export function GetSalesReport(payload) {
  return post("/restaurant/report", payload);
}

export function GetSummary(payload) {
  return post("/restaurant/tablet-report", payload);
}

export function SetBankAccount(payload) {
  return post("/restaurant/set-bank-account", payload);
}

export function GetPayouts() {
  return get("/restaurant/payouts");
}

export function GetRestaurantInfo(payload) {
  return post("/restaurant/get-info", payload);
}

export function GetPayoutInfo(payload) {
  return get("/restaurant/payout-info", payload);
}

// Menu

export function GetMenu() {
  return get("/menu/get");
}

export function AddModifierGroup(payload) {
  return post("/menu/add-modifier-group", payload);
}

export function DeleteModifierGroup(payload) {
  return post("/menu/delete-modifier-group", payload);
}

export function EditModifierGroup(payload) {
  return post("/menu/edit-modifier-group", payload);
}

export function AddCategory(payload) {
  return post("/menu/add-category", payload);
}

export function EditCategory(payload) {
  return post("/menu/edit-category", payload);
}

export function DeleteCategory(payload) {
  return post("/menu/delete-category", payload);
}

export function AddMenuItem(payload) {
  return post("/menu/add-menu-item", payload);
}

export function EditMenuItem(payload) {
  return post("/menu/edit-menu-item", payload);
}

export function DeleteMenuItem(payload) {
  return post("/menu/delete-menu-item", payload);
}

export function AddSchedule(payload) {
  return post("/menu/add-schedule", payload);
}

export function EditSchedule(payload) {
  return post("/menu/edit-schedule", payload);
}

export function DeleteSchedule(payload) {
  return post("/menu/delete-schedule", payload);
}

export function SetCategoryOrder(payload) {
  return post("/menu/set-category-order", payload);
}

export function SetMenuItemOrder(payload) {
  return post("/menu/set-menu-item-order", payload);
}

// Checkout

export function GetCheckout(payload) {
  return get("/checkout/get", payload);
}

export function GetSelfCheckout(payload) {
  return get("/checkout/self", payload);
}

export function SubmitCheckout(payload) {
  return post("/checkout/submit", payload);
}

// Email

export function SendContactEmail(payload) {
  return post("/email/contact", payload);
}

export function SendDemoRequest(payload) {
  return post("/email/demo", payload);
}

// Password Reset

export function SendRecoveryEmail(payload) {
  return post("/pw-resets/add", payload);
}

export function GetReset(payload) {
  return get("/pw-resets/get", payload);
}

export function ResetPassword(payload) {
  return post("/pw-resets/apply", payload);
}

// Promotions

export function AddPromotion(payload) {
  return post("/promotions/add", payload);
}

export function GetPromotions() {
  return post("/promotions/get-partner-promotions");
}

export function GetPromotion(payload) {
  return post("/promotions/get-promotion", payload);
}

export function UpdatePromotion(payload) {
  return post("/promotions/update", payload);
}

export function DeletePromotion(payload) {
  return post("/promotions/delete", payload);
}

// Closures

export function AddClosure(payload) {
  return post("/closures/add", payload);
}

export function GetClosures() {
  return post("/closures/get-closures");
}

export function GetClosure(payload) {
  return post("/closures/get-closure", payload);
}

export function UpdateClosure(payload) {
  return post("/closures/update", payload);
}

export function DeleteClosure(payload) {
  return post("/closures/delete", payload);
}

// orders

export function GetOrdersTable(payload) {
  return post("/order/table", payload);
}

export function GetOrder(payload) {
  return post("/order/get", payload);
}

export function RefundOrder(payload) {
  return post("/order/refund", payload);
}

// s3

export function GetSignedUrl(payload) {
  return get("/images/get-url", payload);
}

export function UploadFile(payload) {
  const { signedRequest, file } = payload;
  const options = { headers: { "Content-Type": file.type } };
  return axios.put(signedRequest, file, options);
}
