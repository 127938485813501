import rs from "randomstring";
import phone from "phone";
import { format } from "d3-format";
import fileDownload from "js-file-download";
import { Parser } from "json2csv";
import moment from "moment-timezone";

export function setToken({ store, token }) {
  store = store || false;
  localStorage.removeItem("token");
  sessionStorage.removeItem("token");
  if (store) localStorage.setItem("token", token);
  else sessionStorage.setItem("token", token);
}

export function replaceToken(token) {
  const localToken = localStorage.getItem("token");
  if (localToken) localStorage.setItem("token", token);
  const sessionToken = sessionStorage.getItem("token");
  if (sessionToken) sessionStorage.setItem("token", token);
}

export function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function removeItem(arr, item) {
  const i = arr.indexOf(item);
  if (i > -1) arr.splice(i, 1);
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function random(entropy = 16) {
  return rs.generate(entropy).toUpperCase();
}

export function validateName(str) {
  if (!str.trim()) return "Please enter your name.";
  if (!str.trim().includes(" ")) return "Please enter your full name.";
  if (!/^[-A-Za-z ]+$/.test(str))
    return "Name cannot contain special characters";
}

export function validateFirstName(str) {
  if (!str.trim()) return "Please enter your first name.";
  if (!/^[-A-Za-z ]+$/.test(str))
    return "First name cannot contain special characters";
}

export function validateLastName(str) {
  if (!str.trim()) return "Please enter your last name.";
  if (!/^[-A-Za-z ]+$/.test(str))
    return "Last name cannot contain special characters";
}

export function validateEmail(str) {
  if (!str.trim()) return "Please enter your email.";
  if (str.trim().includes(" ")) return "Email cannot contain spaces.";
  if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(str.trim()))
    return "Email is not valid.";
}

export function validatePasswords(password, confirm) {
  if (!password.trim()) return "Please create a password.";
  if (!confirm.trim()) return "Please confirm your password.";
  if (password !== confirm) return "Passwords must match.";
  if (password.includes(" ")) return "Password cannot contain spaces.";
  if (password.length < 8) return "Password must be at least 8 characters.";
}

export function parsePhone(pn) {
  return phone(pn)[0];
}

export function validatePhone(pn) {
  if (!pn.trim()) return "Please enter your phone number.";
  if (!phone(pn)[0]) return "Phone number is not valid.";
}

export function toDollars(cents) {
  const dollars = cents / 100;
  if (dollars === 0) return "0";
  return format(",.2f")(dollars);
}

// accounting style dollars
export function toAccounting(cents) {
  const isNeg = cents < 0;
  const dollars = Math.abs(cents / 100);
  if (dollars === 0) return "$0.00";
  let str = "$" + format(",.2f")(dollars);
  if (isNeg) str = `(${str})`;
  return str;
}

export function downloadOrders(data) {
  const output = [];
  for (const x of data) {
    const { money, refundAmount } = x;
    output.push({
      Submitted: moment(x.created).format("lll"),
      "Pickup Time": moment(x.eta).format("lll"),
      Customer: x.name,
      Subtotal: toDollars(money.subtotal),
      Tip: toDollars(money.tip),
      Total: toDollars(money.total),
      Tax: toDollars(money.tax),
      Fee: toDollars(money.ccFee),
      Promo: toDollars(money.promo || 0),
      Proceeds: toDollars(money.proceeds),
      Refund: toDollars(refundAmount || 0),
      "Item Count": x.itemCount,
      "First Name": x.firstName,
      "Last Name": x.lastName,
      Email: x.email,
      Phone: x.phone,
      "User ID": x.userID,
      "Order Type": x.orderType,
      Origin: x.origin,
      "Promo Code": x.promoCode || "",
      "Order ID": x.orderID,
    });
  }

  downloadCsv(output, "Orders.csv");
}

export function downloadRefunds(data) {
  const output = [];
  for (const x of data) {
    output.push({
      Created: moment(x.created).format("lll"),
      Customer: x.name,
      Amount: toDollars(x.amount),
      "Order ID": x.orderID,
    });
  }

  downloadCsv(output, "Refunds.csv");
}

// const data = [{},...]
export function downloadCsv(data, filename) {
  let fields = [];
  if (data[0]) fields = Object.keys(data[0]);
  const parser = new Parser({ fields });
  const file = parser.parse(data);
  fileDownload(file, filename || "ExpressoReport.csv");
}
