import React from "react";
import logo from "../images/expresso.svg";
import logo_w from "../images/expresso_w.svg";
import logo_vw from "../images/expresso_vw.svg";

export default function Logo(props) {
  const { mode = "" } = props;
  const className = props.onClick ? "clickable" : "";
  const height = props.height || 38;

  let src = logo;
  if (mode === "w") src = logo_w;
  if (mode === "vw") src = logo_vw;

  return (
    <img
      onClick={onClick}
      className={className}
      src={src}
      alt="expresso_logo"
      style={{ height, ...props.style }}
    />
  );

  function onClick() {
    if (props.onClick) props.onClick();
  }
}
